import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Trans, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import Container from '@material-ui/core/Container'
import SEO from '../../../components/SEO'
import Layout from '../../../components/totem/layout'
import Comparador from '../../../components/totem/Comparador'
import Loading from '../../../components/Loading'
import ErrorBoundary from '../../../components/ErrorBoundary'

import * as S from '../comparador/styles'
import { ditoTrack } from '../../../helpers/dito'
import BtnVoltar from '../../../components/totem/Voltar'

const ComparadorResultadoPage = ({
  location,
  data: {
    allProduto: { edges: produtos },
  },
  pageContext: { loja },
}) => {
  const [loading, setLoading] = useState(true)
  const { language } = useI18next()
  const { t } = useTranslation()

  function trackComparador() {
    let num = 1
    const data = {}

    location.state !== null &&
      location.state.modelos !== undefined &&
      location.state.modelos.forEach((modelo) => {
        if (modelo.nome) {
          data[`modelo_${num++}`] = modelo.nome
        }
      })
    ditoTrack('comparou-produto', data)
  }

  useEffect(() => {
    if (location.state === null) {
      navigate(`/${language}/comparador/`)
    } else {
      setLoading(false)
    }
    trackComparador()
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <Layout loja={loja}>
      <SEO
        title={t('comparador_title_seo')}
        description={t('description_seo')}
      />
      <S.Bg page="resultado">
        <Container>
          <S.PageTitle>
            <h1>
              <Trans>
              CONFIRA O RESULTADO:
              </Trans>
            </h1>
          </S.PageTitle>
          <ErrorBoundary>
            <Comparador
              modelosSelecionados={location.state.modelos}
              loja={loja}
            />
          </ErrorBoundary>
          <div className="box-btn-sel-mod">
            <S.NavLink to={`/totem/${loja.slug}/comparador`} id="btn-comparador" >
              selecionar outros modelos
            </S.NavLink>
          </div>
        </Container>
      </S.Bg>
      <BtnVoltar></BtnVoltar>
    </Layout>
  )
}

ComparadorResultadoPage.defaultProps = {
  location: {},
}

ComparadorResultadoPage.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]),
  data: PropTypes.shape({
    allProduto: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            cabedal: PropTypes.arrayOf(PropTypes.string),
            drop: PropTypes.arrayOf(PropTypes.string),
            entressola: PropTypes.arrayOf(PropTypes.string),
            foto: PropTypes.string,
            id: PropTypes.string.isRequired,
            alternative_id: PropTypes.number.isRequired,
            indicadoPara: PropTypes.arrayOf(PropTypes.string),
            nome: PropTypes.string.isRequired,
            preco: PropTypes.string,
            slug: PropTypes.string,
            tipoPisada: PropTypes.arrayOf(PropTypes.string),
            solado: PropTypes.arrayOf(PropTypes.string),
            peso: PropTypes.arrayOf(PropTypes.string),
            urlComprar: PropTypes.string,
            localFoto: PropTypes.object.isRequired,
          }),
        })
      ),
    }),
  }),
  pageContext: PropTypes.shape({
    loja: PropTypes.shape({
      nome: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default ComparadorResultadoPage

export const query = graphql`
  query ($language: String!, $slugLoja: String) {
    allProduto(
      filter: {
        alternative_id: { ne: 0 }
        target: { in: "comparador" }
        lojas: { elemMatch: { slug: { eq: $slugLoja } } }
      }
      sort: { fields: nome }
    ) {
      edges {
        node {
          ...produtoCampos
          ...produtoFotoComparador
          pais
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
